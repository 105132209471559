<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-container>
        <v-list dense mt-3>
          <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                link
                @click="signUpCandidate()"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-action>
                  <v-icon>school</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Cadastro Aluno Especial</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Módulo para cadastro de candidato à aluno especial.</span>
          </v-tooltip> -->

         <!--  <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link @click="relatorios()" v-bind="attrs" v-on="on">
                <v-list-item-action>
                  <v-icon>description</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Relatórios Técnicos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Relatórios técnicos emitidos pela comunidade do IC.</span>
          </v-tooltip> -->

          <v-list-item link @click="dialog = !dialog">
            <v-list-item-action>
              <v-icon>help_outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sobre</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-divider class="mx-4" vertical></v-divider>
      <v-toolbar-title>
        <span
          >SiGPos - Sistema de Gerenciamento da Pós-Graduação do
          IC-Unicamp</span
        >
        <span v-if="mode == 'development'" class="ml-3">
            <v-chip color="success" outlined small>Homologação</v-chip> </span
          ><span v-if="mode == 'local'" class="ml-3">
            <v-chip color="blue" outlined small>Local</v-chip>
          </span>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col fill-height>
            <v-card class="login-form" pa3>
              <v-card-title>
                <v-avatar class="mx-auto avatar" height="80px" width="80px">
                  <img src="@/assets/logo_ic.png" alt="logo" />
                </v-avatar>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <label class="text--secondary"
                    >Sistema de Gerenciamento da Pós Graduação</label
                  >
                  <v-divider></v-divider>
                  <br />
                  <v-tooltip bottom class="pa-0" v-model="helpLogin">
                    <template #activator="{}">
                      <v-text-field
                        append-icon="help"
                        @click:append="showHelpLogin()"
                        block
                        outlined
                        name="user"
                        label="Login"
                        autofocus
                        v-model="login.username"
                        @keyup.enter.native="handleLogin()"
                        :disabled="disabled"
                      ></v-text-field>
                    </template>
                    <v-card class="my-2 pa-2" outlined>
                      <v-card-text>
                        <ul>
                          <li>
                            <strong>Candidato</strong> -> email do cadastro
                          </li>
                          <li>
                            <strong>Funcionários/Docentes</strong> -> username
                            do IC
                          </li>
                          <li>
                            <strong>Alunos regulares</strong> -> username do
                            email da DAC [somente números]
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </v-tooltip>

                  <v-tooltip bottom class="pa-0" v-model="helpPass">
                    <template #activator="{}">
                      <v-text-field
                        append-icon="help"
                        @click:append="showHelpPass()"
                        block
                        outlined
                        name="password"
                        label="Senha"
                        type="password"
                        v-model="login.password"
                        @keyup.enter.native="handleLogin()"
                        :disabled="disabled"
                      ></v-text-field>
                    </template>
                    <v-card class="my-2 pa-2" outlined>
                      <v-card-text>
                        <ul>
                          <li>
                            <strong>Candidato</strong> -> senha do cadastro
                          </li>
                          <li>
                            <strong>Funcionários/Docentes</strong> -> senha
                            utilizada no email do IC
                          </li>
                          <li>
                            <strong>Alunos regulares</strong> -> senha utilizada
                            no email da DAC
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </v-tooltip>

                  <v-spacer></v-spacer>
                  <v-btn
                    block
                    dark
                    class="pa-6 bold"
                   
                    label="Login"
                    @click="handleLogin()"
                    :disabled="disabled"
                    >Login</v-btn
                  >

                  <br />
                  <v-progress-linear
                    color="blue accent-2"
                    indeterminate
                    v-show="disabled"
                  ></v-progress-linear>
                  <v-label color="red">{{ message }}</v-label>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="350"
          @keypress.esc="dialog = false"
        >
          <v-card>
            <v-card-title class="headline justify-center">SigPos</v-card-title>
            <v-card-text class="text-center">
              <p>
                Sistema de gerenciamento da pós graduação
                <br />Versão Beta
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      helpLogin: false,
      helpPass: false,
      mode: "",
      login: {
        username: "",
        password: "",
      },
      message: "",
      disabled: false,
      dialog: false,
      drawer: true,
    };
  },
  created() {
    this.mode = process.env.NODE_ENV;
    if (this.$route.params.login) {
      this.handleLoginFailure(this.$route.params.login);
    }
  },
  methods: {
    ...mapActions("auth", ["ActionDoLogin", "ActionSetData"]),

    handleLoginFailure(err) {
      switch (err.status) {
        case 403:
          this.message = "Usuário ou Senha inválida";
          break;
        case 401:
          this.message = "Erro no servidor! Tente novamente.";
          break;
        case 498:
          this.message = err.body.messages[0].message;
          break;
        default:
          this.message =
            "Ocorreu um erro de comunicação com o servidor. Por favor, tente novamente mais tarde.";
      }
    },

    async handleLogin() {
      this.message = "";
      if (this.login.username && this.login.password) {
        this.disabled = true;
        try {
          const res = await this.ActionDoLogin({
            username: this.login.username,
            password: this.login.password,
          });
          if (res.status == 200) {
            await this.ActionSetData(res.data);
            this.$router.push("/home");
          } else {
            this.message =
              "Ocorreu um erro inesperado! Por gentileza tente novamente.";
          }
        } catch (err) {
          this.handleLoginFailure(err);
        } finally {
          this.disabled = false;
        }
      } else {
        this.message = "Preencha todos os campos!";
      }
    },

    hasLoggedOut() {},

    showHelpLogin() {
      this.helpLogin = !this.helpLogin;
    },

    showHelpPass() {
      this.helpPass = !this.helpPass;
    },

    relatorios() {
      this.$router.push({ name: "PublicTechnicalReport" });
    },

    signUpCandidate() {
      this.$router.push({ name: "SignUpCandidate" });
    },
  },
};
</script>


<style>
.avatar {
  text-align: center;
  margin: 0 auto;
}
.login-form {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}
</style>