var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-list",
                { attrs: { dense: "", "mt-3": "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = !_vm.dialog
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("help_outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Sobre")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          }),
          _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
          _c("v-toolbar-title", [
            _c("span", [
              _vm._v(
                "SiGPos - Sistema de Gerenciamento da Pós-Graduação do IC-Unicamp"
              )
            ]),
            _vm.mode == "development"
              ? _c(
                  "span",
                  { staticClass: "ml-3" },
                  [
                    _c(
                      "v-chip",
                      { attrs: { color: "success", outlined: "", small: "" } },
                      [_vm._v("Homologação")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.mode == "local"
              ? _c(
                  "span",
                  { staticClass: "ml-3" },
                  [
                    _c(
                      "v-chip",
                      { attrs: { color: "blue", outlined: "", small: "" } },
                      [_vm._v("Local")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "login-form", attrs: { pa3: "" } },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "mx-auto avatar",
                                  attrs: { height: "80px", width: "80px" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/logo_ic.png"),
                                      alt: "logo"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text--secondary" },
                                    [
                                      _vm._v(
                                        "Sistema de Gerenciamento da Pós Graduação"
                                      )
                                    ]
                                  ),
                                  _c("v-divider"),
                                  _c("br"),
                                  _c(
                                    "v-tooltip",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "append-icon": "help",
                                                  block: "",
                                                  outlined: "",
                                                  name: "user",
                                                  label: "Login",
                                                  autofocus: "",
                                                  disabled: _vm.disabled
                                                },
                                                on: {
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    return _vm.showHelpLogin()
                                                  }
                                                },
                                                nativeOn: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleLogin()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.login.username,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.login,
                                                      "username",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "login.username"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.helpLogin,
                                        callback: function($$v) {
                                          _vm.helpLogin = $$v
                                        },
                                        expression: "helpLogin"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "my-2 pa-2",
                                          attrs: { outlined: "" }
                                        },
                                        [
                                          _c("v-card-text", [
                                            _c("ul", [
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v("Candidato")
                                                ]),
                                                _vm._v(" -> email do cadastro ")
                                              ]),
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v(
                                                    "Funcionários/Docentes"
                                                  )
                                                ]),
                                                _vm._v(" -> username do IC ")
                                              ]),
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v("Alunos regulares")
                                                ]),
                                                _vm._v(
                                                  " -> username do email da DAC [somente números] "
                                                )
                                              ])
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "append-icon": "help",
                                                  block: "",
                                                  outlined: "",
                                                  name: "password",
                                                  label: "Senha",
                                                  type: "password",
                                                  disabled: _vm.disabled
                                                },
                                                on: {
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    return _vm.showHelpPass()
                                                  }
                                                },
                                                nativeOn: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleLogin()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.login.password,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.login,
                                                      "password",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "login.password"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.helpPass,
                                        callback: function($$v) {
                                          _vm.helpPass = $$v
                                        },
                                        expression: "helpPass"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "my-2 pa-2",
                                          attrs: { outlined: "" }
                                        },
                                        [
                                          _c("v-card-text", [
                                            _c("ul", [
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v("Candidato")
                                                ]),
                                                _vm._v(" -> senha do cadastro ")
                                              ]),
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v(
                                                    "Funcionários/Docentes"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " -> senha utilizada no email do IC "
                                                )
                                              ]),
                                              _c("li", [
                                                _c("strong", [
                                                  _vm._v("Alunos regulares")
                                                ]),
                                                _vm._v(
                                                  " -> senha utilizada no email da DAC "
                                                )
                                              ])
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-6 bold",
                                      attrs: {
                                        block: "",
                                        dark: "",
                                        label: "Login",
                                        disabled: _vm.disabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleLogin()
                                        }
                                      }
                                    },
                                    [_vm._v("Login")]
                                  ),
                                  _c("br"),
                                  _c("v-progress-linear", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.disabled,
                                        expression: "disabled"
                                      }
                                    ],
                                    attrs: {
                                      color: "blue accent-2",
                                      indeterminate: ""
                                    }
                                  }),
                                  _c("v-label", { attrs: { color: "red" } }, [
                                    _vm._v(_vm._s(_vm.message))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "v-row",
          { attrs: { justify: "center" } },
          [
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "350" },
                on: {
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    _vm.dialog = false
                  }
                },
                model: {
                  value: _vm.dialog,
                  callback: function($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline justify-center" },
                      [_vm._v("SigPos")]
                    ),
                    _c("v-card-text", { staticClass: "text-center" }, [
                      _c("p", [
                        _vm._v(" Sistema de gerenciamento da pós graduação "),
                        _c("br"),
                        _vm._v("Versão Beta ")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }